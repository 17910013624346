<template>
  <v-app>
    <Navbar />
    <v-main class="about" >
      <v-container>
        <v-row justify="center">
          <v-col cols="12" md="8" lg="6" class="text-center">
            <h1 class="display-1">About Me</h1>
            <p class="subheading">TBD</p>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'Ho-me',
  components: {
    Navbar,
    Footer,
  },
};
</script>

<style scoped>

.about{
  background: rgb(2,0,36);
  background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(25,25,112,1) 51%, rgba(0,0,255,1) 95%);
  color: white;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 1s ease-out forwards;
}

.fade-in-delayed {
  opacity: 0;
  animation: fadeIn 1s ease-out forwards;
  animation-delay: 0.5s;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
</style>

