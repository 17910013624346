<template>
  <v-app>
    <Navbar />
    <v-main class="projects" >
      <v-container>
        <v-row justify="center">
          <v-col cols="12" md="8" lg="6">
            <h1 class="text-center">Projects</h1><br>
            <h1 class="text-center">In Progress:</h1><br>
            <h1 class="display-1">Mineapolis</h1>
            <p>Mineapolis is supposed to be an online, free monopoly-like game. It's in works since September 2024, and ETA for release is December 2024/January 2025 (We'll see about that). </p><br>
            <h1 class="text-center">School Projects:</h1><br>
            <v-btn text to="/adammalysz">Ski Jump Simulator</v-btn>
            <p>Project made in 2023, for Mr. Dudek, the best teacher in my high school. Of course, in my today's standards it needs refactoring at least (and native Vue support).</p>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'Ho-me',
  components: {
    Navbar,
    Footer,
  },
};
</script>

<style scoped>

.projects{
  background: rgb(2,0,36);
  background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(25,25,112,1) 51%, rgba(0,0,255,1) 95%);
  color: white;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 1s ease-out forwards;
}

.fade-in-delayed {
  opacity: 0;
  animation: fadeIn 1s ease-out forwards;
  animation-delay: 0.5s;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
</style>

