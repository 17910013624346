<template>
    <v-app-bar>
      <v-toolbar-title>Szafruga Software Inc.</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text to="/">Home</v-btn>
      <v-btn text to="/projects">Projects</v-btn>
      <v-btn text to="/about">About</v-btn>
    </v-app-bar>
  </template>
  
  <script>
  export default {
    name: 'Nav-bar',
  };
  </script>
  
  <style scoped>
  .v-app-bar.v-toolbar  {
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.2);
    background: transparent;
    color: white;
  }
  </style>
  